
.App{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    align-items: center;
}

.content-container{
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    padding: 0 8px;
}

button {
    text-transform: uppercase !important;
    font-family: Montserrat !important;
    font-weight: 550 !important;
    color: white !important;
}


.authContainer {
    width: 800px;
}

a {
    text-decoration-line: none;
    color: var(--main-theme-active-color);
}

a:hover {
    color: var(--main-theme-color);
    font-weight: 900;
}

.btn {
    border: solid 1px var(--main-button-color);
    color: var(--main-button-text-color);
}

.accordion-button, .accordion-button:not(.collapsed){
    background-color: var(--main-button-color);
    color: var(--main-button-text-color);
    padding: 10px 20px;
}

.btn:hover {
    background: var(--main-button-color);
    color: white;
}

@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 100% !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
textarea, textarea:active, textarea:focus {
    border: solid 1px #ced4da !important;
    min-height: 100px;
    outline: none !important;
    color: var(--main-text-color);
    border-radius: 8px;
    padding: 6px 12px;
    width: 100%;
}

.newCompetitionMap, .publicLocationMap {
    height: 300px;
    width: 100%;
    max-width: 100%;
}

.competitionLogo img {
    max-height: 250px;
}

.user-selector .btn-primary {
    min-width: 300px;
}

.edit-competition .comp-img {
    position: relative;
    width: var(--mini-img-width);
}

.sport-category-trick {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 8fr 26px;
    border-radius: 8px;
}

.sport-category-trick > div {
    padding: 5px 2px 2px 5px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: #AAAAAA solid 1px;
}

.add-competition-tricks, .current-competition-tricks, .competition-criteria-list {
    padding: 8px 8px;

}

.header{
    font-weight: 900;
}

.userPage, .parkPage, .public-page, .indexPage, .edit-competition, .park-queue-management-page {
    max-width: 1200px;
    width: 100%;
}

.authContainer {
    max-width: 100%;
}


.park-queue-management-page .timer {
    font-size: 4rem;
    font-weight: 800;
}

.park-queue-management-page .queue_member {
    margin-top: 5px;
    font-size: 1.1rem;
}

.start_btn {
    width: 160px;
    height: 82px;
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
    padding: 10px 55px !important;
    background-color: green !important;
    border-color: green !important;

}

.pause_btn {
    width: 160px;
    height: 82px;
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
    padding: 10px 55px !important;
    background-color: red !important;
    border-color: red !important;
}

.current-competition-tricks .header, .current-competition-tricks .trick {
    display: grid;
    border-bottom: #AAAAAA 1px solid;
    grid-template-columns: 3fr 3fr 5fr 2fr 2fr 24px;
    gap: 5px;
    align-items: center;
}

.add-competition-tricks .header, .add-competition-tricks .trick {
    display: grid;
    border-bottom: #AAAAAA 1px solid;
    grid-template-columns: 3fr 2fr 7fr 24px;
    gap: 5px;
    align-items: center;
}

.public-page .team {
    border-bottom: 1px solid var(--main-theme-active-color);
    padding: 8px 0px;
}

.team_accordion_btn button, .team_accordion_btn button:not(.collapsed), .team_accordion_btn {
    background-color: white;
    box-shadow: none !important;
    border: none;
}

.team_accordion_btn button::after, .team_accordion_btn button:not(.collapsed)::after {
    /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333333'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");*/
    background-image: url("./styles/svgs/double_arrow.svg");
}

.competition-team-list .header, .competition-team-list .team {
    display: grid;
    grid-template-columns: 3fr 2fr 5fr 8fr;
    gap: 10px;
    border-bottom: #AAAAAA 1px solid;
    align-items: center;
    padding: 10px 10px;
}

@media screen and (max-width: 800px) {
    .public-page .competition-team-list .header, .public-page .competition-team-list .team {
        grid-template-columns: 2fr 2fr 2fr;
        grid-template-areas:
    "a b c"
    "d d d"
    "e e e";

    }

    .competition-team-list .header {
        text-align: center;
    }

    .competition-team-list .header, .competition-team-list .team{
        grid-template-columns: 2fr 2fr 4fr;
        grid-template-areas:
    "a b c"
    "d d d";
    }

    .competition-team-list .header, .competition-team-list .total {
        grid-area: d ;
    }

    .public-page .competition-team-list .team .accordion {
        grid-area: e;
    }

    .competition-team-list .team .accordion {
        grid-area: d;
    }
    .competition-team-list .team .team-mini-img {
        grid-area: a;
    }
    .competition-team-list .team .team-color {
        grid-area: b;
    }
    .competition-team-list .team .team-name {
        grid-area: c;
    }
}

.team .logoContainer {
    border: solid 1px var(--main-theme-active-color);
    border-radius: 100%;
}
.team .team-logo {
    border: solid 3px white;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 2.2rem;
    color: var(--main-theme-text-color);
    line-height: 10px;
    padding: 2px 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ActionJackson;
    font-weight: 800;
    text-transform: uppercase;
}

.team .team-name {
    text-transform: uppercase;
}


.competitionPartner .partner_logo {
    max-height: 60px;
    max-width: 150px;
}

.pointer {
    cursor: pointer;
}

.myParkEditLink {
    font-size: 1.5rem;
    border-bottom: 1px solid var(--main-theme-color);
    padding-bottom: 8px;
}

.index-competition {
    border: #333333 2px solid;
     border-radius: 6px;
    width: 800px;
    max-width: 95vw;
    position: relative;
    margin-top: 30px;
}

.index-competition .dateBlock {
    position: absolute;
    left: 35px;
    top: 0;
    transform: translateY(-50%);
}

.index-competition .content .name {
    font-family: ActionJackson;
    font-size: 2.5rem;
}

.index-competition .imgBlock img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 6px
}

.competitionPartners {
    flex-wrap: wrap;
}

.index-competition .info {
    border-bottom: #333333 2px solid;
    border-radius: 4px;
    padding: 35px 20px 25px 20px;
    position: relative;
    margin-bottom: 20px
}

.index-competition .info .content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 15px;
}

.competition-page .rules_btn{
    background-color: white;
    color: var(--main-theme-text-color);
    border: solid 2px var(--main-button-color);
    border-radius: 6px;
    padding: 8px 10px 6px 10px;
    font-weight: 800;
    transition: all ease-in-out 300ms;
    width: 100%;
}

.competition-page .rules_btn:hover {
    background-color: var(--main-button-color);
    color: white;
}

.index-competition .info .content .registerStatusBlock {
    position: absolute;
    bottom: 0;
    right: 20px;
    transform: translateY(50%);
}

.registerStatus {
    padding: 8px 6px;
    border-radius: 5px;
    background: white;
}

.registerStatus.active {
    color: limegreen;
    border: solid 1px limegreen;
}
.registerStatus.done {
    color: darkred;
    border: solid 1px darkred;
}

@media screen and (max-width: 800px) {
    .index-competition .info .content{
        grid-template-columns: 1fr;
    }
    .index-competition .imgBlock img {
        max-height: 200px;
    }
    .competitionPartner img {
        max-height: 60px;
    }
}

.index-competition .info .content .btn {
    justify-self: flex-end;
}



.competition-criteria-list .header, .competition-criteria-list .modifier {
    display: grid;
    border-bottom: #AAAAAA 1px solid;
    grid-template-columns: 50px 1fr 3fr 1fr 50px 50px 1fr 24px;
    gap: 5px;
    align-items: center;
}

.add-change-team .change-img, .add-change-contestant .change-img {
    max-height: 300px;
    border-radius: 8px;
    margin: 8px 0;
}

.competition-groups-list .header, .competition-groups-list .group{
    display: grid;
    margin-top: 10px;
    grid-template-columns: 6fr 2fr 2fr 24px;
    grid-template-areas:
    "a b c d"
    "e e e e";
    border-bottom: #AAAAAA 1px solid;
    align-items: center;
    gap: 5px
}

.competition-control .group-list {
    max-width: 100%;
    width: 700px;
}

.competition-control .group-list .header, .competition-control .group-list .group {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
}

.competition-groups-list .group .accordion{
    grid-area: e;
}

.competition-control .group,.competition-control .header {
    border-bottom: 1px #AAAAAA solid;
}

.heat-trick-list .trick .form-control {
    width: 55px;
}

.heat-trick-list .header,
.heat-trick-list .trick {
    border-bottom: 1px solid #AAAAAA;
}

.heat-trick-list .header > div:first-child,
.heat-trick-list .trick > span:first-child {
    width: 30px;
    margin-left: 10px;
    flex: 0 1 30px !important;
    align-self: center;
}

.next-round-block {
    border-top: #AAAAAA solid 1px;
    border-bottom: #AAAAAA solid 1px;
    margin-top: 15px;
    padding-bottom: 15px;
}


@media screen and (max-width: 600px) {



    .heat-trick-list .header {
        font-size: 0.5em;
    }
    .heat-trick-list .trick {
        font-size: 0.6em;
    }
    .competition-control .round{
        font-size: 0.9em;
        padding: 0 0;
    }
    .competition-control .status{
        font-size: 0.9em;
        padding: 0 0;
    }
}

.contestant-trick-list .header, .contestant-trick-list .trick {
    display: grid;
    grid-template-columns: 8fr 2fr 2fr 2fr;
    border-bottom: #AAAAAA 1px solid;
    align-items: center;
    gap: 5px
}


.contestant-page .heat-groups {
    border-top: #AAAAAA 2px solid;
}

.solo-competition-contestants-list .header, .solo-competition-contestants-list .contestant {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
    border-bottom: #AAAAAA 1px solid;
    align-items: center;
    gap: 5px
}
.solo-competition-contestants-list .contestant img {
    max-height: 50px;
    cursor: pointer;
}
.public-page  .team-mini-img img {
    max-width: 90px;
    max-height: 70px;

}

.competition-page .competition-name {
    font-family: ActionJackson;
    font-size: 4.5rem;
}

.competition-page .content{
    border: 1px solid var(--main-theme-active-color);
    border-radius: 8px;
    padding: 24px 8px 8px 8px;
    position: relative;
}

.competition-page .content .head {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 10px;
}

.competition-page .content .reg_btn{
    font-size: 1.5rem;
}

@media screen and (min-width: 991px){
    .content-container {
        margin-top: 25px;
    }
    .competition-page .content .head{
        grid-template-columns: 2fr 3fr;
    }
    .competition-page .content .head div:nth-child(2){
        justify-self: end
    }
}


.competition-page .content .dateTimeBlock {
    position: absolute;
    left: 35px;
    top: 0;
    transform: translateY(-50%);
}

.QTY_block input{
    width: 50px;
    font-weight: 800;
    border-radius: 0;
}

.QTY_block .dec_btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.QTY_block .inc_btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.team-mini-img img {
    max-width: 50px;
    max-height: 40px;
    cursor: pointer;
}

.full-img-modal .full-img {
    max-width: 100%;
    max-height: 1000px;
}

.hide-edit-block {
    color: var(--main-theme-color);
    font-size: 1.3rem;
    cursor: pointer;
    font-weight: 900;
    display: flex;
    justify-content: right;
}

.contestant-trick-list .accordion .accordion-button {
    text-align: center;
    display: block;
    padding: 3px 10px;
}

.back-nav-btn {

    position: relative;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .add-competition-tricks div, .sport-category-trick div, .current-competition-tricks div{
        font-size: 0.8rem;
    }

    .content-container {
        margin-top: 80px;

    }
}

@media screen and (max-width: 700px) {
    .add-competition-tricks div, .sport-category-trick div, .current-competition-tricks div{
        font-size: 0.7rem;
    }

}

.sport-category-trick div:last-child {
    padding: 4px 0 0 0;
}

.sport-category-trick div:nth-child(2), .sport-category-trick div:nth-child(3){
    text-align: center;
}

.sport-category-trick.trick div:first-child input{
    margin-left: 5px;
}

.sport-category-trick.trick .del-btn {
    align-self: center;
}

.sports-categories-modal .form-check label{
    line-height: 15px;
}

.active {
    color: var(--main-theme-color);
    font-weight: 700;
}

.add-mini-btn {
    cursor: pointer;
}

.del-mini-btn {
    color: #AAAAAA;
    cursor: pointer;
    font-size: 1rem;
    width: 24px;
}

.mini-btn {
    cursor: pointer;
}

.sport-edit-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
}

.brand-edit-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
}

.sport-edit-row div:last-child {
    justify-self: right;
}

@media screen and (max-width: 800px){
    .brand-edit-row {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 991px){
    .sport-edit-row button {
        font-size: 0.7em;
        padding: 0 0;
    }

}

.user-list-modal .user-list{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    gap: 5px;
}

.user-list-modal .user-list div:nth-child(2) {
    justify-self: center;
}

.btn-primary:disabled{
    background-color: transparent;
    border-color: #AAAAAA;
    color: #AAAAAA;
    font-weight: 900;
}

.del-btn {
    color: orangered ;
    cursor: pointer;
    text-shadow: 3px 3px 5px #FFFFFF;
}

.accordion-button {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 1.1rem;
}

.accordion-button:after, .accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


.accordion-button:active, .accordion-button:focus, .accordion-button:hover{
    box-shadow: 0 0 8px rgba(0,0,0, 0.5);
}

.gallery-item .del-btn {
    position: absolute;
    top: 5px;
    left: 15px;
}
.gallery  {
    max-width: 100%;
    margin: 0 auto;
    column-count: 3;
}

.gallery-item {
    break-inside: avoid;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
}

.gallery-item img {
    border-radius: 8px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
}


@media screen and (min-width: 1200px) {

    .gallery img{
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {

    .gallery img{
        max-width: 100%;
    }
}

@media screen and (max-width: 1000px) {

    .gallery {
        column-count: 2;
    }
    .gallery-item img {
        width: 47vw;
        max-width: 47vw;
    }
}

@media screen and (max-width: 450px) {
    .gallery {
        column-count: 1;
    }

    .gallery-item {
        display: flex;
        justify-content: center;
    }

   .gallery-item img {
       max-width: 88vw;
       width: 88vw;
    }
}

.switch-line {
    height: 20px;
}

.switch-line .form-switch {
    padding-left: 47px;
}

.switch-line .active  {
    font-weight: 700;
    color: var(--main-theme-color)
}

input, .form-control {
    color: var(--main-text-color);
}

textarea:focus {
    color: #444444;
}

input[type=number] {
    -moz-appearance: textfield;
}

h1, h2, h3, h4, h5, h6, p, div {
    color: var(--main-text-color);
    font-family: var(--main-font);
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;
}

#responsive-navbar-nav.show  {
    overflow-y: scroll;

}

#responsive-navbar-nav::-webkit-scrollbar{
    display: none;
}

.mainContainer {
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-grow: 1;
    justify-content: center;

}
.modal-content input {
    margin: 5px 0;
}

.btn-primary {
    background-color: var(--main-theme-color);
    border-color: var(--main-theme-color);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    text-shadow: 0px 0px 7px #FFFFFF;
    background-color: var(--main-theme-color) !important;
    border-color: var(--main-theme-color)  !important;;

}

.form-check-input:checked {
    background-color: var(--main-theme-color);
    border-color: var(--main-theme-color);
}

.form-control:focus, .form-check-input:focus, textarea:focus {
    border-color: var(--main-theme-color);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--main-theme-color);

}

@media screen and (max-width: 991px){
    .mainContainer {
        margin-top: 80px;
    }
    #responsive-navbar-nav{
        background-color: #777777;
        opacity: 0.8;
        padding: 10px 0px 10px 0px;
    }
    #responsive-navbar-nav .active {
        background-color: var(--main-theme-color);
        text-shadow: none;
    }
}

.parksContainer .parkMap {
    width: 100%;
    height: 90vh;
    max-height: 100%;
    margin-right: 15px;
}

.pagination {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

}

.pagination li:before{
    content: '';
}

.pagination .page-link {
    cursor: pointer;
    background-color: white;
    color: var(--main-button-color);
    border-color: var(--main-button-color);
}
.pagination .page-link:hover {
    color: #FFFFFF;
    background-color: var(--main-button-color);
}

.pagination .active > .page-link {
    color: #FFFFFF;
    background-color: var(--main-theme-active-color);
    border-color: var(--main-theme-active-color);
}



.paginationDelimiter a{
    border-top: none;
    border-right: none;
    border-bottom: none;
    cursor: default;
}
.paginationDelimiter:hover, .paginationDelimiter a:hover {
    background-color: transparent !important;
    color: var(--main-theme-text-color) !important;
}

.park_img_carousel {
    max-height: 400px;

}

.park_img_carousel div img {
    border-radius: 4%;
    object-fit: cover;
    height:100%;
    width:100%;
    max-height: 400px;
}

/*VK ID*/
.VkIdWebSdk__button_reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.VkIdWebSdk__button {
    background: var(--main-theme-color);
    cursor: pointer;
    transition: all .1s ease-out;
}

.VkIdWebSdk__button:hover{
    opacity: 0.8;
}

.VkIdWebSdk__button:active {
    opacity: .7;
    transform: scale(.97);
}

.VkIdWebSdk__button {
    border-radius: 8px;
    width: 100%;
    min-height: 44px;
}

.VkIdWebSdk__button_container {
    display: flex;
    align-items: center;
    padding: 8px 10px;
}

.VkIdWebSdk__button_icon + .VkIdWebSdk__button_text {
    margin-left: -28px;
}

.VkIdWebSdk__button_text {
    display: flex;
    font-family: -apple-system, system-ui, "Helvetica Neue", Roboto, sans-serif;
    flex: 1;
    justify-content: center;
    color: #ffffff;
}


.equipmentCategoryModalSubcategoryLine:hover, .equipmentCategoryModalSubcategoryLine:focus, .equipmentCategoryModalSubcategoryLine:active {
    color: var(--main-theme-light-color);
    cursor: pointer;
}

.parkListItem {
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: -3px 3px 5px rgba(0,0,0,0.2);
}

.parkListItem img {
    width: 100%;
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 6px;
}

.socialLinks svg {
    height: 40px;
    font-size: 40px;
    width: 40px;
}

.parkListItem:hover {
    cursor: pointer;
    box-shadow: -3px 3px 5px rgba(0,0,0,0.6);
}


/* 404 page */




.container_404{
    height: 100%;
    width: 100%;
    margin: 0px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}



.h2_404{

    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    color: #ff005d;
    font-weight: 300;
    font-family: Days;
    animation: fadeInText 500ms ease-in 0.1s forwards, flicker4 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;

}

.sign_404 {
    font-family: Exo2;
    font-size: 8rem;
    color: #ff005d;
}
#id_404_1{
    animation: flicker1 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}
#id_404_2{
    animation: flicker2 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}
#id_404_3{
    animation: flicker3 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}



@keyframes flicker1{
    0%  {color: #ff005d; text-shadow: #ff005d;}
    1%  {color: transparent; text-shadow: transparent;}
    3%  {color: transparent; text-shadow: transparent;}
    4%  {color: #ff005d; text-shadow: #ff005d;}
    6%  {color: #ff005d; text-shadow: #ff005d;}
    7%  {color: transparent; text-shadow: transparent;}
    13% {color: transparent; text-shadow: transparent;}
    14% {color: #ff005d; text-shadow: #ff005d;}
    100%{color: #ff005d; text-shadow: #ff005d;}
}

@keyframes flicker2{
    0%  {color: #ff005d; text-shadow: #ff005d;}
    50% {color: #ff005d; text-shadow: #ff005d;}
    51% {color: transparent; text-shadow: transparent;}
    61% {color: transparent; text-shadow: transparent;}
    62% {color: #ff005d; text-shadow: #ff005d;}
    100%{color: #ff005d; text-shadow: #ff005d;}
}

@keyframes flicker3{
    0%  {color: #ff005d; text-shadow: #ff005d;}
    1%  {color: transparent; text-shadow: transparent;}
    10% {color: transparent; text-shadow: transparent;}
    11% {color: #ff005d; text-shadow: #ff005d;}
    40% {color: #ff005d; text-shadow: #ff005d;}
    41% {color: transparent; text-shadow: transparent;}
    45% {color: transparent; text-shadow: transparent;}
    46% {color: #ff005d; text-shadow: #ff005d;}
    100%{color: #ff005d; text-shadow: #ff005d;}
}

@keyframes flicker4{
    0%  {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    30% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    31% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    32% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    36% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    37% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    41% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    42% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    85% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    86% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    95% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    96% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    100%{color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
}

@keyframes fadeInText{
    1%  {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    70% {color: #ff005d;text-shadow:0px 0px 14px #ff005d;}
    100%{color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
}

@keyframes hueRotate{
    0%  {
        filter: hue-rotate(0deg);
    }
    50%  {
        filter: hue-rotate(-120deg);
    }
    100%  {
        filter: hue-rotate(0deg);
    }
}



