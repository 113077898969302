* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --main-font: Montserrat;
  --main-theme-color: #333333;
  --main-theme-light-color: #dcbff5;
  --main-text-color: #333333;
  --main-theme-text-color: #333333;
  --mini-img-width: 360px;
  --main-theme-active-color: #333333;
  --main-button-color: #333333;
  --main-button-text-color: #ffde00;
  --main-theme-gradient-light: #AAAAAA
}

body {
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;

}

@font-face {
  font-family: "Exo2";
  src: url("./styles/fonts/Exo2-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./styles/fonts/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "ActionJackson";
  src: url("./styles/fonts/actionj.ttf") format("opentype");
}

html, body {height: 100%}

html {
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

img {
  max-width: 95vw;
}


.App{
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  align-items: center;
}

